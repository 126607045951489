<template>
    <div class="container">
        <back-btn></back-btn>
        <div class="content-wrap">
            <page-title-card></page-title-card>
            <div class="progress-card">    
                <div class="flex align-center progress-wrap">
                    <k-progress class="progress"
                        :percent="finishWordPercent"
                        status="warning"
                        :line-height="16"
                        :show-text="false"
                        bg-color="#3f465744">
                    </k-progress>
                    <div class="number-wrap">{{ finishWordNumber }} / {{ totalWordNumber }}</div>
                </div>
                <div class="flex align-center progress-wrap">
                    <k-progress class="progress"
                        :percent="timePercent"
                        status="warning"
                        :line-height="16"
                        :show-text="false"
                        bg-color="#3f465744">
                    </k-progress>
                    <div class="number-wrap">{{ formatTime }}</div>
                </div>
            </div>
            <div class="word-card">
                <transition-group name="list" tag="div">
                    <button v-for="word in randomWords"
                        :key="word"
                        :class="{
                            'checked': selects.includes(word),
                            'removed': selects.includes(word) && isRemoving
                        }"
                        :style="`background-color: ${colors[word]}; border-color: ${colors[word]};`"
                        @click="selectWord(word)">
                        {{ word }}
                    </button>
                </transition-group>
            </div>
        </div>

        <!-- 成功 -->
        <success-dialog :show.sync="showSuccessDialog"
            :time="formatTime"
            :errorCount="errorCount"
            :wordCount="totalWordNumber"
            :lastCatalog="lastCatalog"
            @again="againGame"
            @continue="continueGame">
        </success-dialog>

        <!-- 失败 -->
        <fail-dialog :show.sync="showFailDialog"
            @again="againGame">
        </fail-dialog>

        <!-- 错误语音提示 -->
        <audio id="fail" src="../../assets/fail.mp3"></audio>
        <!-- 成功语音提示 -->
        <audio id="success" src="../../assets/success.mp3"></audio>
    </div>
</template>

<script>
import KProgress from 'k-progress';
import SuccessDialog from "./success-dialog.vue";
import FailDialog from "./fail-dialog.vue"

export default {
    components: {
        KProgress,
        FailDialog,
        SuccessDialog
    },
    data () {
        return {
            selects: [],
            randomWords: [], // 随机打乱数据
            percent: 0,
            colors: {},
            timer: null,
            timerInterval: 0,
            showFailDialog: false,
            showSuccessDialog: false,
            isRemoving: false,
            errorCount: 0,
        }
    },
    watch: {
        wordList: {
            handler (val) {
                this.initWordData();
            },
            immediate: true
        }
    },
    computed: {
        // 路由参数
        routeParams () {
            return this.$route.params;
        },

        // 获取单元列表
        unitList () {
            return this.$store.getters.getUnitList(this.routeParams.volumeId) || [];
        },

        // 单元词汇量
        wordList () {
            return this.$store.getters.getWordList(this.routeParams.volumeId, this.routeParams.catalogId);
        },

        // 总词汇量
        totalWordNumber () {
            return this.wordList.length;
        },

        // 完成的数量
        finishWordNumber () {
            return this.wordList.length - this.randomWords.length/2;
        },

        // 完成进度百分比
        finishWordPercent () {
            return Math.min(parseInt(this.finishWordNumber/this.totalWordNumber * 100), 100);
        },
        
        // 格式化倒计时
        formatTime () {
            if (this.timerInterval) {
                let minute = parseInt((this.totalWordMaxTime - this.timerInterval) / 60), second = (this.totalWordMaxTime - this.timerInterval) % 60;
                minute = minute < 10 ? `0${minute}` : minute;
                second = second < 10 ? `0${second}` : second;
                return `${minute}:${second}`;
            }
            return '00:00';
        },

        // 总的词汇需要的最大时间
        totalWordMaxTime () {
            // 单词汇预览5s
            const wordSecond = 5,
                totalSeconds = (this.totalWordNumber) * wordSecond;

            return totalSeconds;
        },

        // 倒计时百分比
        timePercent () {
            // 单词汇预览5s
            return Math.min(parseInt(this.timerInterval/this.totalWordMaxTime * 100), 100);
        },

        // 倒计时结束
        timeIsOver () {
            return this.timerInterval >= this.totalWordMaxTime;
        },

        // 是否本册最后一单元
        lastCatalog () {
            const index = (this.unitList || []).findIndex(p => p.id == this.routeParams.catalogId);
            return index == this.unitList.length;
        },

        // 本册下一单元
        nextCatalog () {
            if (!this.lastCatalog) {
                const index = (this.unitList || []).findIndex(p => p.id == this.routeParams.catalogId);
                return this.unitList[index+1];
            }
            return null;
        }
    },
    methods: {
        // 初始化数据
        initWordData () {
            this.errorCount = 0;
            this.selects = [];
            this.randomWords = this.$store.getters.getRandomWordsList(this.routeParams.volumeId, this.routeParams.catalogId);
            this.randomBgColor();
            this.createTime();
        },

        // 创建定时器
        createTime () {
            if (this.timer) {
                clearInterval(this.timer);
            }
            this.timer = setInterval(() => {
                if (!this.timeIsOver) {
                    this.timerInterval++;
                } else {
                    // 倒计时结束
                    clearInterval(this.timer);

                    // 到时间是否已完成消一消
                    this.checkGameSuccess();
                }
            }, 1000)
        },

        // 选择词汇
        selectWord (word) {
            if (this.timeIsOver) {
                return;
            }

            let isWordEn = false;
            if (this.selects.length < 2 && !this.selects.includes(word)) {
                if (this.wordList.find(p => p.wordEn == word)) {
                    isWordEn = true;
                    this.speckWord(word);
                }
                this.selects.push(word);
            } else {
                this.selects = [];
            }

            if (this.wordList.find(p => this.selects.includes(p.wordEn) && this.selects.includes(p.wordCh))) {
                !isWordEn && this.playAudio('success');
                this.isRemoving = true;
                setTimeout(() => {
                    this.randomWords = this.randomWords.filter(p => !this.selects.includes(p));
                    this.selects = [];
                    this.checkGameSuccess();
                    this.isRemoving = false;
                }, 500)
                
            } else if (this.selects.length == 2){
                !isWordEn && this.playAudio('fail');
                this.selects = [];
                this.errorCount++;
            }
        },

        // 随机背景色
        randomBgColor() {
            const randoms = ['A', 'B', 'C', 'D', 'E', 'F', '6', '7', '8', '9'];
            const colors = [
                '#736EFE', '#D939CD', '#A64DBF', '#CA26FF',
                '#360940', '#123597', '#0D25B9', '#1D6FA3',
                '#5151E5', '#C32BAC', '#00EAFF', '#4C83FF',
                '#3677FF', '#EA5455', '#28C76F', '#9F44D3'
            ];
            this.randomWords.map(p => {
                const index = parseInt(Math.random()*colors.length),
                    alpha0 = parseInt(Math.random()*randoms.length),
                    alpha1 = parseInt(Math.random()*randoms.length);
                this.colors[p] = `${colors[index]}${randoms[alpha0]}${randoms[alpha1]}`
            });
        },

        // 播放语音提示
        playAudio (id) {
            let audio = document.getElementById(id);
            audio.play();
        },

        // 校验时间结束|已完成消一消
        checkGameSuccess() {
            if (this.timeIsOver && this.randomWords.length) {
                this.showFailDialog = true;
                this.playAudio('fail');
            } else if (!this.randomWords.length) {
                this.clearTime();
                this.showSuccessDialog = true;
                
                // 已完成处理 - 保存本地
                this.$store.dispatch('saveUnitRecord', {
                    ...this.routeParams,
                    challenge: {
                        percent: this.timePercent,
                        time: this.timerInterval + 's',
                    }
                })
            }
        },

        // 清空定时器
        clearTime () {
            if (this.timer) {
                clearInterval(this.timer);
            }
        },

        // 重新挑战
        againGame () {
            this.timerInterval = 0;
            this.initWordData();
        },

        // 继续挑战
        continueGame () {
            this.timerInterval = 0;
            if (this.nextCatalog) {
                this.$router.replace({
                    path: '/game/' + this.routeParams.volumeId + '/' + this.nextCatalog.id
                })
            }
        },

        // 语音合成
        speckWord (word) {
            this.$speck.initTTS({
                text: word,
                onend: () => {
                    console.log('播放完成------------')
                }
            });
        }
    },
    destroyed () {
        this.clearTime();
        this.$speck.cancelTTS();
    }
}
</script>

<style lang="scss" scoped>
.content-wrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 2;
    // animation: fadeIn 1s linear forwards;
    color: rgba($color: #000000, $alpha: 1.0);
    .progress-card {
        box-sizing: border-box;
        padding: 10px 20px 10px 5.5rem;
        width: 100%;
        height: 100px;
        
        .progress-wrap {
            margin: 5px 0;
            .progress {
                width: 100%;
                margin-bottom: 0px;
                /deep/ .k-progress-outer {
                    padding-right: 10px;
                }
            }
            .number-wrap {
                flex-shrink: 0;
                min-width: 4.3rem;
                text-align: right;
                font-size: 20px;
                font-weight: bold;
            }
        }
    }

    .word-card {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        flex-shrink: 0;
        flex-grow: 0;
        overflow-y: auto;
        position: relative;
        height: calc(100vh - 140px);
        box-sizing: border-box;

        &>div{
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-content: flex-start;
        }

        button {
            padding: 0 var(--rap-word-pad-x);
            height: var(--rap-word-h);
            min-width: var(--rap-word-min-w);
            margin: var(--rap-word-gap-x) var(--rap-word-gap-y);
            font-size: 20px;
            font-weight: bold;
            box-sizing: border-box;
            border-radius: 10px;
            color: rgba($color: #ffffff, $alpha: .9);
            transition: all .2s;
            outline: none;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;

            &.checked {
                transform: translateY(-5px);
                background: rgba($color: #E80505, $alpha: .9) !important;
                border-color: rgba($color: #E80505, $alpha: .9) !important;
                box-shadow: 0 2px 8px rgba($color: #E80505, $alpha: .6);
                transition: all .3s ease-in;
            }

            &.removed {
                opacity: 0;
                visibility: hidden;
                transition: none;
            }

            &:active {
                opacity: 0.8;
                transition: all .3s ease-in-out;
            }
        }
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.list-move, /* 对移动中的元素应用的过渡 */
.list-enter-active,
.list-leave-active {
    transition: all .5s ease;
}

.list-enter-from,
.list-leave-to {
    opacity: 0;
}

/* 确保将离开的元素从布局流中删除
  以便能够正确地计算移动的动画。 */
.list-enter-active,
.list-leave-active {
    position: absolute;
}
</style>