<template>
    <div class="star-rate" :style="offsetPosition">
        <div :class="[`item-rate ${type}`]"
            v-for="(item, index) in rates"
            :key="item.accuracy">
            <div class="star-card" :style="`width: ${width}px; height: ${width}px;`">
                <img src="@/assets/star_grey.svg" alt="">
                <img :style="`clip: rect(0, ${computedWidth(index)}px, ${width}px, 0);`" src="@/assets/star.svg" alt="">
            </div>            
        </div>
    </div>
</template>

<script>
export default {
    props: {
        accuracy: [String, Number],
        type: { // 排列方式 默认弧线
            type: String,
            default: 'curve'
        },
        width: { // 星星大小
            type: Number,
            default: 70
        },
        middleOffset: { // 中间两颗星偏移量
            type: String,
            default: '10px'
        },
        sideOffset: { // 两边星星偏移量
            type: String,
            default: '25px'
        },
        gap:  {
            type: Number,
            default: 20
        }
    },

    data() {
        return {
            rates: [
                { accuracy: 20 },
                { accuracy: 40 },
                { accuracy: 60 },
                { accuracy: 80 },
                { accuracy: 100 },
            ],
        }
    },

    computed: {
        offsetPosition() {
            return {
                '--middle-offset': this.middleOffset,
                '--side-offset': this.sideOffset,
                '--gap-offset': this.gap + 'px'
            };
        },
    },

    methods: {
        computedWidth(index) {
            console.log(this.accuracy, index)
            return this.accuracy ? Math.max((this.accuracy - index * 20) * this.width, 0) : 0;
        }
    }
}
</script>

<style lang="scss" scoped>
    .star-rate {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: var(--gap-offset);
        .item-rate {
            .star-card {
                position: relative;
                img {
                    display: inline-block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
            }
            &.curve {
                &:first-child, &:last-child {
                    transform: translateY(var(--side-offset));
                }
                &:nth-child(2), &:nth-child(4) {
                    transform: translateY(var(--middle-offset));
                }
            }
           
        }
    }
</style>