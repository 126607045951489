<template>
    <div class="dialog-card fail show" :class="{'show': show}" v-show="show">
        <div class="content-body">
            <img class="face-appear" style="width: 119px;" src="@/assets/face-fail.svg" alt="">
            <div class="title-wrap">挑战失败, 再接再厉</div>
            <div class="btn-wrap">
                <button class="btn again" @click="doAgain">重新挑战</button>
                <button class="btn back" @click="doBack">返回</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        show: Boolean
    },

    methods: {
        // 再次挑战
        doAgain () {
            this.$emit('update:show', false);
            this.$emit('again');
        },

        // 继续挑战
        doBack () {
            this.$emit('update:show', false);
            this.$nextTick(() => {
                this.$router.go(-1);
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.dialog-card.fail {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 9;
    opacity: 0;
    background: rgba($color: #1F254D, $alpha: .5);
    backdrop-filter: blur(10px);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    &.show {
        animation: fade .5s ease-in-out forwards;
    }

    .content-body {
        background: rgba($color: #fff, $alpha: .8);
        backdrop-filter: blur(10px);
        padding: 30px 5% 30px 5%;
        border-radius: 30px;
        max-width: 80%;
        margin: auto;
        animation: translateFade .5s ease-in-out forwards;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        overflow: hidden;

        .title-wrap {
            padding: 20px 0;
            font-size: 2rem;
            color: #F56C6C;
            font-weight: bold;
            text-align: center;
            .catalog {
                font-size: 36px;
            }
        }

        .btn-wrap {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 20px 0;
            .btn {
                margin: 0 10px;
                height: 50px;
                padding: 0 20px;
                border-radius: 10px;
                font-size: 16px;
                font-weight: 500;
                border: none;
                color: white;
                transition: all .2s;
                    
                &.again {
                    background: darkcyan;
                }

                &.back {
                    background: darkgoldenrod;
                }

                &:active {
                    box-shadow: 0 2px 12px 0 rgba($color: #000000, $alpha: .2);
                    opacity: 0.6;
                    transition: all .3s;
                }
            }
        }

        .face-appear {
            animation: shake 0.4s linear .8s 1;

            @keyframes shake {
                0% { transform: translateX(0); }
                10%, 90% { transform: translateX(-4px) translateY(0px); }
                20%, 80% { transform: translateX(4px) translateY(2px); }
                30%, 70% { transform: translateX(-2px)  translateY(0px); }
                40%, 60% { transform: translateX(2px)  translateY(2px); }
                50% { transform: translateX(0); }
            }
        }
    }
}

@keyframes translateFade {
    0% {
        opacity: 0;
        transform: translateY(-50px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fade {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
</style>