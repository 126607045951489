<template>
    <div class="dialog-card success show" :class="{'show': show}" v-show="show" @click.stop>
        <div class="content-body">
            <div class="content-wrap">
                <div class="title-wrap">挑战成功</div>
                <div class="list">
                    <div class="item">单词总数 <span>{{ wordCount }}</span></div>
                    <div class="item">耗时 <span>{{ time }}</span></div>
                    <div class="item">出错次数 <span>{{ errorCount || '0' }}</span></div>
                    <div class="item">准确率 <span>{{ accuracy || '100%' }}</span></div>
                </div>
                <div class="start-card">
                    <star-rate :accuracy="accuracy" :width="20" :gap="12" type=""></star-rate>
                </div>
                <div class="line"></div>
                <div class="btn-wrap">
                    <div class="item-button">
                        <span v-if="errorCount">{{ errorCount }}个单词需要复习</span>
                        <button>复习单词</button>
                    </div>
                    <div class="item-button">
                        <button @click="doAgain">重新挑战</button>
                    </div>
                    <div class="item-button" v-if="!lastCatalog">
                        <button @click="doContinue">继续挑战</button>
                    </div>
                    <div class="item-button">
                        <button @click="$router.go(-1)">退出</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import starRate from '@/components/star-rate.vue';

export default {
    props: {
        show: Boolean,
        time: String,
        wordCount: Number,
        errorCount: Number,
        lastCatalog: Boolean
    },
    components: {
        starRate
    },
    data () {
        return {
            animation: null
        }
    },
    computed: {
        // 准确率
        accuracy () {
            return parseInt(this.wordCount/(this.wordCount + this.errorCount) * 100) + '%'
        }
    },
    methods: {
        // 再次挑战
        doAgain () {
            this.$emit('update:show', false);
            this.$emit('again');
        },

        // 继续挑战
        doContinue () {
            this.$emit('update:show', false);
            this.$emit('continue');
        }
    }
}
</script>

<style lang="scss" scoped>
.dialog-card.success {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 9;
    opacity: 0;
    background: rgba($color: #1F254D, $alpha: .5);
    backdrop-filter: blur(10px);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    &.show {
        animation: fade .5s ease-in-out forwards;
    }

    .content-body {
        padding: 20px 0;
        width: 80%;
        margin: auto;
        color: white;
        transform: translateY(50px);
        animation: translateFade .5s ease-in-out forwards;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;

        .content-wrap {
            width: 100%;
            .title-wrap {
                font-size: 30px;
                font-weight: bold;
                text-align: center;
            }
            .list {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                .item {
                    width: 50%;
                    padding: 5px 10px;
                    box-sizing: border-box;
                    font-weight: bold;
                    font-size: 20px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    span {
                        margin: 0 10px;
                        font-size: 18px;
                        font-weight: 400;
                        color: rgba($color: #ffffff, $alpha: .9);
                    }
                }
            }
            .start-card {
                margin: 10px 0 15px 0;
            }
            .line {
                margin: 0;
                height: 1px;
                width: 100%;
                background: white;
            }
            .btn-wrap {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding: 20px 0;
                
                .item-button {
                    padding: 10px 0;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    button {
                        background: transparent;
                        outline: none;
                        border: none;
                        font-size: 28px;
                        font-weight: bold;
                        color: rgba(228, 219, 57, 1);
                    }
                }
            }
        }
    }
}

@keyframes translateFade {
    0% {
        opacity: 0;
        transform: translateY(-50px);
    }
    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}

@keyframes fade {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
</style>